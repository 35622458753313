
import { parse } from "querystring";
import { defineComponent } from "vue";
import { createNamespacedHelpers } from "vuex";

const { mapActions } = createNamespacedHelpers("auth");

export default defineComponent({
  async created() {
    try {
      const values = parse(this.$route.hash.replace("#", ""));
      await this.login(values);
      this.$router.push({ name: "dashboard" });
    } catch (e) {
      this.$router.push({ name: "auth.login" });
    }
  },
  methods: {
    ...mapActions(["login"]),
  },
  setup() {
    return {
      loading: true,
    };
  },
});
